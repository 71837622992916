// extracted by mini-css-extract-plugin
export const sidebar = "sidebar-module--sidebar--39Iy6";
export const top = "sidebar-module--top--SV3Qd";
export const logo = "sidebar-module--logo--1KjMG";
export const subtitle = "sidebar-module--subtitle--2d1XK";
export const categories = "sidebar-module--categories--3W7nG";
export const category = "sidebar-module--category--2nbgJ";
export const header = "sidebar-module--header--torjJ";
export const topPosts = "sidebar-module--topPosts--2SxEW";
export const topPost = "sidebar-module--topPost--2uJP3";
export const thumbnail = "sidebar-module--thumbnail--Tv1jn";
export const desc = "sidebar-module--desc--1udcY";
export const title = "sidebar-module--title--3Nt2y";