import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import Posts from "../components/posts/posts";
// import Pagination from "../components/pagination/pagination";
import * as styles from "./posts-template.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby"
const SearchPostsTemplate = (props) => {

  const data = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: {
            isFuture: { eq: false }
            isDraft: { eq: false }
          }
        ) {
          nodes {
            frontmatter {
              title
              tags
              date
              categories
              author
              coverImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            title
            html
            isFuture
            url
            isDraft
            tags
            categories
            id
            searchString
            excerpt(format: PLAIN, truncate: true, pruneLength: 500)
          }
        }
      }
    `
  );

  const posts = data.allMarkdownRemark.nodes;

  let params = new URLSearchParams(props.location.search);
  let query = params.get("q");
  let results = []

  const search = (query) => {
    if (query) {
      results = posts.filter(post => {
        return post.searchString.toLowerCase().includes(query.toLowerCase())
      })
    }
    if (results.length === 0) {
      if (typeof window !== 'undefined') {
        navigate("/");
      }
    }

  }

  search(query);


  return (
    <React.Fragment>
      <div className={"row " + styles.wrapper}>
        <div className={"col-lg-3 col-xs-12 " + styles.side}>
          <Sidebar />
        </div>
        <div className="col-lg-9 col-xs-12">
          <Posts posts={results} />
          {/* <Pagination
            nextPage={props.pageContext.nextPagePath}
            prePage={props.pageContext.previousPagePath}
          /> */}
        </div>
      </div>
    </React.Fragment >
  );
};

export default SearchPostsTemplate;
