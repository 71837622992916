import React from "react";
import * as styles from "./sidebar.module.scss";
import { Link, graphql, useStaticQuery } from "gatsby";
import logoImg from "../../images/logo.png";
import { GatsbyImage } from "gatsby-plugin-image"

const Sidebar = () => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            subTitle
          }
        }
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: {
            isFuture: { eq: false }
            isDraft: { eq: false }
            pinned: { eq: true }
          }
        ) {
          nodes {
            title
            url
            frontmatter {
              date
              coverImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  );
  let posts = data.allMarkdownRemark.nodes;
  return (
    <React.Fragment>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <Link className={styles.logo} to="/">
            <img src={logoImg} alt={data.site.siteMetadata.title} />
          </Link>
          <h1>{data.site.siteMetadata.title.toUpperCase()}</h1>
          <h1 className={styles.subtitle}>{data.site.siteMetadata.subTitle}</h1>
        </div>
        <div className={styles.topPosts}>
          {posts.map((post, index) => {
            return (
              <article className={styles.topPost} key={index}>
                <Link className={styles.thumbnail} to={post.url}>
                  <GatsbyImage
                    image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
                    alt={post.title}
                  />
                </Link>
                <div className={styles.desc}>
                  <Link className={styles.title} to={post.url}>
                    <h2>{post.title}</h2>
                  </Link>
                  <p>{post.frontmatter.date}</p>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
