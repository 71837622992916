import React from "react";
import * as styles from "./posts.module.scss";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const Posts = (props) => {
  let posts = props.posts;
  return (
    <React.Fragment>
      <div className={styles.posts}>
        {posts.map((post, index) => {
          return (
            <div className={styles.post} key={index}>
              <div className={styles.header}>
                <h2 className={styles.title}>
                  <Link to={post.url}>{post.title}</Link>
                </h2>
                <p className={styles.date}>{post.frontmatter.date}</p>
              </div>
              <div className={styles.featureImage}>
                <GatsbyImage
                  image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
                  alt={post.title}
                />
              </div>
              <p>{post.excerpt}</p>
              <div className={styles.button}>
                <Link to={post.url}>Read More</Link>
              </div>
              <ul className={styles.metas}>
                <li>
                  categories:
                  {post.categories.map((category, index) => {
                    return (<span key={index}>
                      <Link to={"/categories/" + category.toLowerCase()}>
                        {category.toLowerCase()}
                      </Link>
                    </span>)
                  })}
                </li>
                <li>
                  tags:
                  {post.tags.map((tag, index) => {
                    return (
                      <span key={index}>
                        <Link to={"/tags/" + tag.toLowerCase()}>{tag.toLowerCase()}</Link>
                      </span>
                    );
                  })}
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Posts;
